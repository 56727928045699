<router-outlet *ngIf="!appLoading"></router-outlet>
<mat-progress-bar mode="buffer" *ngIf="appLoading" value={{loadingProgress}}
  style="z-index: 200000; position: fixed; top: 0">
</mat-progress-bar>
<div id="app-main-loader" class="splash-container" *ngIf="appLoading">
  <img id="splash-logo" class="anim-pulsating" src="assets/images/mr-logo.png" alt="Loading...">
  <div>
    <h1 style="margin-bottom: 46px;" style="text-align: center;">Preparing Wakanda</h1>
    <h3 style="text-align: center; margin-bottom: 24px;">
      <span style="color: #3458B2;">{{currentLoadingItem}}</span>...
    </h3>
    <h5 style="text-align: center;">{{loadingProgress}}% completed</h5>
  </div>
</div>


<div *ngIf="isCoach">
  <button mat-raised-button color="primary" class="support-button">
    <i class="fa fa-headset"></i>
    Live Support
  </button>
</div>
