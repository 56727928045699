<div fxLayout="row" fxLayout.lt-md="row wrap" fxFlex="grow">
  <mat-card fxFlex="100%" fxLayout="column" style="height: 100%; min-height: 400px; width: 50%; padding: 2px !important">
    <div class="member-header-container">
      <div class="member-chip" *ngIf="student?.firstName">
        {{student?.firstName}} {{student?.lastName}}
        <button class="close-button" style="font-size: x-large;" (click)="navigateToDashboard()">×</button>
      </div>
      <div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <strong class="text-muted">Search Options</strong>
            </mat-panel-title>
          </mat-expansion-panel-header>
        
          <div fxFlex="none" class="search-input-container" [style.display]="'block'">
            <div class="conversation-headar w-100">
              <ng-template #rt let-r="result" let-t="term" class="item-inner">
                <h5 *ngIf="!r" class="p-3" style="text-align: center">No results found...</h5>
                <app-message *ngIf="r" (click)="jumpToDateInConversation(r._student, r.createdAt)" [message]="r" [student]="student">
                </app-message>
              </ng-template>
        
              <div fxLayout="column" class="conversation-upper">
                <div id="toggle-grid-container" style="display: flex; padding-bottom: 10px;">
                  <input id="toggle-grid-item" (click)="toggle('textSearch')" type="radio" name="searchToggle" value="textSearch" checked />
                  <label id="toggle-grid-item">search by text</label>
                  <input id="toggle-grid-item" (click)="toggle('dateSearch')" type="radio" name="searchToggle" value="dateSearch"/>
                  <label id="toggle-grid-item">search by date</label> 
                </div>
                <ng-template [ngIf]="toggleCalender=='textSearch'">
                  <div style="position: relative;">
                    <mat-form-field appearance="outline" class="search-field">
                      <mat-label>Search conversation...</mat-label>
                      <div matPrefix>
                        <div *ngIf="isSearching">
                          <span class="mr-spinner d-inline-block"></span>
                        </div>
                        <mat-icon *ngIf="!isSearching" color="primary" matPrefix>search</mat-icon>
                      </div>
                      <input
                        #searchInput
                        matInput
                        type="text"
                        [(ngModel)]="searchModel"
                        [ngbTypeahead]="search"
                        [resultTemplate]="rt"
                        placeholder="keyword e.g. hello"
                        (focus)="onSearchFocus()"
                        (blur)="onSearchBlur()"
                      />
                      <button
                        mat-icon-button
                        matSuffix
                        aria-label="Refresh conversations"
                        (click)="handleRefreshMessages()"
                      >
                        <mat-icon color="primary">refresh</mat-icon>
                      </button>
                    </mat-form-field>
                  </div>
                  
                  
                </ng-template>
                <ng-template [ngIf]="toggleCalender=='dateSearch'">
                  <mat-form-field appearance="outline" class="search-field">
                    <mat-label>Search conversation by date...</mat-label>
                    <div matPrefix>
                      <div *ngIf="isSearching">
                        <span class="mr-spinner d-inline-block"></span>
                      </div>
                      <mat-icon *ngIf="!isSearching" color="primary" matPrefix>search</mat-icon>
                    </div>
                    <input
                      matInput
                      [(ngModel)]="formControls.selecteDate"
                      (dateSelect)="onDateSelect($event)"
                      [ngbTypeahead]="search"
                      [resultTemplate]="rt"
                      type="date"
                    />
                  </mat-form-field>
                </ng-template>    
        
                <div class="row mb-3">
                  <div class="row">
                    <div class="col" *ngIf="!memberEngaged">
                      <button mat-raised-button color="primary" (click)="onClickSetMemberEngaged(true)">
                        Engage
                      </button>
                    </div>
                    <div class="col" *ngIf="memberEngaged">
                      <button mat-raised-button color="warn" (click)="onClickSetMemberEngaged(false)">
                        Disengage
                      </button>
                    </div>
                    <div class="col" *ngIf="!memberEOS">
                      <button mat-raised-button color="primary" (click)="onClickSetMemberEndOfShiftMessage(true)">
                        Enable EOS
                      </button>
                    </div>
                    <div class="col" *ngIf="memberEOS">
                      <button mat-raised-button color="warn" (click)="onClickSetMemberEndOfShiftMessage(false)">
                        Disable EOS
                      </button>
                    </div>
                    <div class="col" *ngIf="isAdmin">
                      <button mat-raised-button color="primary" (click)="onViewAllCrisis()">
                        All Crisis
                      </button>
                    </div>
                  </div>
                </div>
              </div>
        
              <div class='refresh-container item-inner'>
              </div>
            </div>
        
            <div class="search-info-bar" *ngIf="isSearchMode">
              Viewing archives
              <a tabindex="-1" class="cursor-pointer search-info-bar__link" (click)="exitSearch()">
                Exit search
                <i class="fa fa-arrow-down"></i>
              </a>
            </div>
          </div>


        </mat-expansion-panel>
      </div>
    </div>

    <div id="messages-container" fxFlex fxLayout="column">
      <div fxFlex="initial" fxLayout="row" fxLayoutGap="5px" *ngIf="selectedConversationIds.length"
        fxLayoutAlign="space-around start" class="p-2 mat-elevation-z1" style="position: relative; z-index: 1">
        <div fxLayout="column">
          <mat-checkbox [(ngModel)]="textRestrictions.isHiddenFromCoach">
            Hide from Coaches
          </mat-checkbox>

          <mat-checkbox [(ngModel)]="textRestrictions.isHiddenFromSupervisor">
            Hide from Supervisors
          </mat-checkbox>
        </div>

        <button type="submit" class="btn btn-primary btn-sm"
          [disabled]="!textRestrictions.isHiddenFromCoach && !textRestrictions.isHiddenFromSupervisor"
          (click)="submitConversationsToHide()">
          <i class="fa fa-eye-slash"></i>
          Hide {{selectedConversationIds.length}} selected message(s)
        </button>
        <button type="submit" class="btn btn-danger btn-sm" (click)="clearSelectAndResctrictions()">
          <i class="fa fa-times"></i>
        </button>
      </div>
      <!-- <virtual-scroller fxFlex #virtualScroller [items]="searchedMessages || messages" [enableUnequalChildrenSizes]="true" style="height: 100%;"> -->
      <div id="wakanda-messaging-main" fxFlex style="height: 100%;">
        <div [style.opacity]="isLoadingMore? 1 : 0" style="position: relative; height: 30px;">
          <span class="mr-spinner"></span>
        </div>
        <a href="javascript:;" class="d-block text-center" (click)="onClickLoadMore({ type: 'before' })">Load
          more...</a>

        <div #container>
          <div class="message-outer-container" fxLayout="row" *ngFor="let message of (searchedMessages || messages)"
            [class.sent]="message._sender?.type === 'Coach' || message._sender?.type === 'Admin'">

            <app-message [message]="message" [studentNotes]="student._data.notes" (onNotesSaved)="refetchCurrentStudent()" (onSelect)="selectConversation($event)" (onLinkRecommendation)="handleNewLinkedRec($event)" fxFlex [student]="student"></app-message>

            <div fxLayoutAlign="center center" *ngIf="selectedConversationIds.length" style="padding: 10px">
              <mat-checkbox (ngModelChange)="onCheckConversation(message._id, $event)"
                [ngModel]="selectedConversationsDict[message._id]"></mat-checkbox>
            </div>
          </div>
        </div>
        <div class="text-muted mt-5" *ngIf="!(searchedMessages || messages).length" style="text-align: center">
          <i class="fa fa-comment-dots fa-5x"></i>
          <p style="font-size: 16px">No Messages</p>
        </div>

        <a href="javascript:;" *ngIf="isSearchMode" class="d-block text-center"
          (click)="onClickLoadMore({ type: 'after' })">Load more...</a>
        <div *ngIf="(searchedMessages || messages).length" [style.opacity]="isLoadingMore? 1 : 0"
          style="position: relative; height: 30px;">
          <span class="mr-spinner"></span>
        </div>
      </div>
    </div>

    <div class="">
      <div class="row">
        <div class="col-6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Filters</mat-label>
            <mat-select [(ngModel)]="selectedCategory" (ngModelChange)="setFilterCategory($event)" name="categories">
              <mat-option *ngFor="let item of cannedCategories" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field appearance="fill" class="w-100">
            <mat-label>Templates</mat-label>
            <mat-select [(ngModel)]="selectedCannedMessage" (ngModelChange)="copyText()" name="messages">
              <mat-option *ngFor="let item of selectedMessages" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="">
      <div *ngIf="student._data.isEscalated">
        <small class="fw-bold text-danger mb-1">
          Messages sent would be TIER 2 messages
        </small>
      </div>

      <div class="row" *ngIf="!isReviewMode && canText">
        <div class="col" style="width: 100%; height: 100px;">
          <app-message-input [model]="message" (modelChange)="onMessageChange($event)"
            (sendMessage)="sendMessage($event)" [mode]="'CHAT'" [student]="student"></app-message-input>
        </div>
      </div>
    </div>
  </mat-card>

  <div fxFlex="100%" fxLayout="row" style="position: relative; width: 50%">
    <div fxLayout="column" style="overflow: auto">
      <app-chat-sidebar [student]="student" (onNotesSaved)="refetchCurrentStudent()" [shouldRefetchRecommendation]="shouldRefetchRecommendation" [studentSlots]="studentSlots$ | async">
      </app-chat-sidebar>
    </div>
  </div>
</div>
