import { NgRedux } from "@angular-redux/store";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { CoachService } from "app/core/services/coach.service";
import { PartnerService } from "app/core/services/partner.service";
import { StudentService } from "app/core/services/student.service";
import { IAppState } from "app/redux/store";
import { AuthService } from "app/core/services/auth.service";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { MemberFilter } from "./member-filter.state";
import * as _ from "lodash";
import { AppService } from "app/core/services/app.service";
import { loadManagerPermissionSuccess } from "app/redux/actions/dashboard.actions";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";
import { SHIFT_TIMES } from "app/helpers/constants";
import { getMemberAgeRange } from "app/helpers/date";
import moment from 'moment-timezone'

@Component({
  selector: "member-filters",
  templateUrl: "./member-filters.component.html",
  styleUrls: ["./member-filters.component.scss"],
})
export class MemberFiltersComponent implements OnInit {
  @Input() disableShadow = false;
  @Input() disablePartnersWellNessSurvey = false;
  @Input() EnableDateRange = false;

  startMaxDate;
  endMinDate;

  dateHelperButtonState = {
    '1d': false,
    '7d': false,
    'start of school year': false
  };

  filterOptions = {
    status$: null,
    partner$: null,
    coachTeam: null,
    shiftTime: null,
    shiftDay: null,
    wellnessSurveyGroup: null,
    raceAndEthnicity$: null,
    gender$: null,
    orientation$: null,
    city: null,
    name: null,
    ages: null,
    show: 'all-time',
    startDate: null,
    endDate: null,
    engagementStatusOptions: null,
  };

  statusSelectedAll = false;
  partnerSelectedAll = false;
  coachTeamSelectedAll = false;
  shiftTimeSelectedAll = false;
  shiftDaySelectedAll = false;
  wellnessSurveyGroupSelectedAll = false;
  raceAndEthnicitySelectedAll = false;
  genderSelectedAll = false;
  orientationSelectedAll = false;
  citySelectedAll = false;
  wellnessSurveyGroupsNoData = false;
  agesSelectedAll = false;
  
  //dashboard filter
  @ViewChild("statusFilter") statusFilter: MatSelect;
  @ViewChild("partnerFilter") partnerFilter: MatSelect;
  @ViewChild("coachTeamFilter") coachTeamFilter: MatSelect;
  @ViewChild("shiftTimeFilter") shiftTimeFilter: MatSelect;
  @ViewChild("shiftDayFilter") shiftDayFilter: MatSelect;
  @ViewChild("wellnessSurveyGroupFilter") wellnessSurveyGroupFilter: MatSelect;
  @ViewChild("raceAndEthnicityFilter") raceAndEthnicityFilter: MatSelect;
  @ViewChild("genderFilter") genderFilter: MatSelect;
  @ViewChild("orientationFilter") orientationFilter: MatSelect;
  @ViewChild("cityFilter") cityFilter: MatSelect;
  @ViewChild("agesFilter") agesFilter: MatSelect;
  @ViewChild("engagementStatusFilter") engagementStatusFilter: MatSelect;

  memberFilter = MemberFilter;
  memberFilterCopy = { ...this.memberFilter }; // does not hold reference of memberfilter so it wont mutate it
  @Output() onMemberFilterUpdate = new EventEmitter<object>();
  @Output() memberFilterData$ = new EventEmitter<object>();

  shiftTimes = SHIFT_TIMES;

  shiftDays = [
    {
      label: "Monday",
      value: "1",
    },
    {
      label: "Tuesday",
      value: "2",
    },
    {
      label: "Wednesday",
      value: "3",
    },
    {
      label: "Thursday",
      value: "4",
    },
    {
      label: "Friday",
      value: "5",
    },
    {
      label: "Saturday",
      value: "6",
    },
    {
      label: "Sunday",
      value: "7",
    },
  ];

  engagementStatusOptions = [
    {
      displayTitle: "ACTIVE ENGAGED",
      value: "ACTIVE_ENGAGED",
    },
    {
      displayTitle: "NOT YET ENGAGED",
      value: "NOT_YET_ENGAGED",
    },
  ];

  WellnessSurveyData = [
    {
      label: "SWEMWBS + NO SIAOS",
      value: "1",
    },
    {
      label: "SWEMWBS + PHQ9",
      value: "2",
    },
    {
      label: "SWEMWBS + GAD7",
      value: "3",
    },
    {
      label: "SWEMWBS + PCLC6",
      value: "4",
    },
    {
      label: "AmeriHealth SWEMWBS",
      value: "5",
    },
    {
      label: "Opted Out",
      value: "6",
    },
    {
      label: "1st-time SWEMWBS",
      value: "7",
    },
  ];

  memberFilterOptions = {
    coachTeam: [],
    city: [],
  };
  managerCoachView = true;
  partnerView = true;
  shiftDayView = true;
  shiftTimeView = true;
  currentUser;
  wellnessSurveyGroups = [];

  constructor(
    private auth: AuthService,
    private studentService: StudentService,
    private partnerService: PartnerService,
    private coachService: CoachService,
    private store: NgRedux<IAppState>,
    private app: AppService
  ) {}

  ngOnInit() {
    this.auth.getCurrentUser().subscribe((user) => {
      this.currentUser = user;
    });

    if (this.currentUser.type == "Manager") {
      this.coachService
        .getManagerPermission(this.currentUser._data._id)
        .subscribe((manager) => {
          this.store.dispatch(
            loadManagerPermissionSuccess(manager._permissions)
          );
          this.broadcastUpdate();
        });
    } else {
      this.broadcastUpdate();
    }
    // load data
    this.loadData();
  }

  async loadData() {
    try {
      // remove unnecessary call to getCoaches this introduce an overhead to load all coaches
      // this has been handled on the server
      // Get coaches
      // const allCoaches = await this.getCoaches();
      // this.coaches = allCoaches;
      const memfilter = await this.coachService.loadAssignedMemberFilters();
      // Get member filters
      this.memberFilterOptions = memfilter || {
        coachTeam: {},
        city: [],
      };

      this.loadFilterOptions();
    } catch (error) {
      console.error(error);
    }
  }

  broadcastUpdate() {
    const membertype = this.app.currentUser.type;
    this.memberFilterCopy = { ...this.memberFilter };

    if (membertype == "Manager") {
      this.managerCoachView = false;
      // this could be used to get coach permission on the front end, restriction for this case
      // has been handled on the backend that's why i commented this out
      // this.memberFilter.coachTeam= this.coachService.getManagerCoachPermissions();
    } else {
      this.managerCoachView = true;
    }

    Object.keys(this.memberFilterCopy).forEach((item) => {
      if (
        Array.isArray(this.memberFilterCopy[item]) &&
        this.memberFilterCopy[item].includes("0")
      ) {
        this.untoggleAllSelection(item.toString());
        this.memberFilterCopy[item] = "noData";
      }
    });

    this.memberFilterData$.emit(this.memberFilterCopy);
    this.onMemberFilterUpdate.emit(this.memberFilterCopy);
  }

  loadFilterOptions() {
    const raceSelections =
      this.store.getState().entities.dashboard.profileSelections["race"];
    const genderSelections =
      this.store.getState().entities.dashboard.profileSelections["gender"];
    const sexualOrientationSelections =
      this.store.getState().entities.dashboard.profileSelections[
        "sexualOrientation"
      ];

    // Get statuses
    this.filterOptions.status$ = this.getStatusOptions();

    // Get partners
    this.filterOptions.partner$ = this.partnerService.getPartners().pipe(
      map((partners) => {
        return partners.map((val) => {
          return {
            value: val._id,
            label: val.name,
          };
        });
      })
    );

    this.filterOptions.engagementStatusOptions =
      this.engagementStatusOptions.map((val) => {
        return {
          value: val.value,
          label: val.displayTitle,
        };
      });

    // Get shit time and shift days
    this.filterOptions.shiftTime = [...this.shiftTimes];
    this.filterOptions.shiftDay = [...this.shiftDays];

    // TODO: Get wellness survey groups from member profile
    this.filterOptions.wellnessSurveyGroup = [...this.WellnessSurveyData];

    // Get race/ethnicity
    this.filterOptions.raceAndEthnicity$ = of(
      Object.keys(raceSelections).map((val) => {
        return {
          value: val,
          label: raceSelections[val],
        };
      })
    );

    // Get gender
    this.filterOptions.gender$ = of(
      Object.keys(genderSelections).map((val) => {
        return {
          value: val,
          label: genderSelections[val],
        };
      })
    );

    // Get orientation
    this.filterOptions.orientation$ = of(
      Object.keys(sexualOrientationSelections).map((val) => {
        return {
          value: val,
          label: sexualOrientationSelections[val],
        };
      })
    );

    /// Get coach teams
    this.filterOptions.coachTeam = Object.keys(
      this.memberFilterOptions.coachTeam
    ).map((item) => {
      return {
        value: this.memberFilterOptions.coachTeam[item],
        label: item,
      };
    });

    // Get city options
    this.filterOptions.city = this.memberFilterOptions.city.map((item) => {
      return {
        value: item,
        label: item,
      };
    });

    // Get wellness survey Group
    this.filterOptions.wellnessSurveyGroup = [...this.WellnessSurveyData];
    this.filterOptions.ages = getMemberAgeRange();
  }
  getStatusOptions = () => {
    return this.studentService.getStatusesOptions().pipe(
      map((statuses) => {
        const options = statuses.map((s) => s.status);
        const inactiveStatusIndex = options.indexOf("INACTIVE");
        options.splice(inactiveStatusIndex + 1, 0, "EXITED");
        return options.map((option) => {
          return {
            value: option,
            label: option,
          };
        });
      })
    );
  };

  getCityOptions = () => {
    return this.coachService.getAssignedStudents().pipe(
      map((students) => {
        const options = students.map((s) => s.city);
        return Array.from(new Set(options)).map((option) => {
          return {
            value: option,
            label: option,
          };
        });
      })
    );
  };

  clearDateHelperButtonState() {
    this.dateHelperButtonState = {
      '1d': false,
      '7d': false,
      'start of school year': false
    };
  }

  onSelectShowFilter() {
    if (this.filterOptions.show === 'custom-range') {
      const startDate = moment().startOf('day');
      const endDate = moment().endOf('day');

      this.endMinDate = startDate.format();
      this.startMaxDate = endDate.format();

      this.filterOptions['startDate'] = startDate.format();
      this.filterOptions['endDate'] = endDate.format();
    } else {
      this.filterOptions.startDate = null
      this.filterOptions.endDate = null
    }

    this.broadcastUpdate();
  }

  onDateInputChange(whichDate: 'startDate' | 'endDate', momentDate: moment.Moment, doNotClearButtonState?) {
    if (!doNotClearButtonState) {
      this.clearDateHelperButtonState();
    }
    if (whichDate === 'startDate') {
      momentDate = momentDate.startOf('day');
      this.memberFilter['startDate'] = momentDate.format();
      this.memberFilter['endDate'] = this.memberFilter['endDate'] ||moment().endOf('day').format();
      this.endMinDate = momentDate.toDate();
    } else if (whichDate === 'endDate') {
      momentDate = momentDate.endOf('day');
      this.memberFilter['endDate'] = momentDate.format();
      this.startMaxDate = momentDate.toDate();
    } else {
      this.memberFilter.startDate = null
      this.memberFilter.endDate = null
    }
  }

  clearFilter() {
    for (let filterOption in this.memberFilter) {
      if (this.memberFilter.hasOwnProperty(filterOption)) {
        this.memberFilter[filterOption] = "";
      }
    }
    this.broadcastUpdate();
  }

  untoggleAllSelection(item) {
    switch (item) {
      case "status":
        this.statusSelectedAll = false;
        this.statusFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "shiftTime":
        this.shiftTimeSelectedAll = false;
        this.shiftTimeFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      default:
        break;
      case "partner":
        this.partnerSelectedAll = false;
        this.partnerFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "coachTeam":
        this.coachTeamSelectedAll = false;
        this.coachTeamFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "shiftDay":
        this.shiftDaySelectedAll = false;
        this.shiftDayFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "wellnessSurveyGroup":
        this.wellnessSurveyGroupSelectedAll = false;
        this.wellnessSurveyGroupFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        this.wellnessSurveyGroupsNoData = true;
        break;
      case "raceAndEthnicity":
        this.raceAndEthnicitySelectedAll = false;
        this.raceAndEthnicityFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "orientation":
        this.orientationSelectedAll = false;
        this.orientationFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "gender":
        this.genderSelectedAll = false;
        this.genderFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "city":
        this.citySelectedAll = false;
        this.cityFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "ages":
        this.agesSelectedAll = false;
        this.agesFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "engagementStatus":
        this.engagementStatusFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
    }
  }

  toggleAllSelection(item) {
    switch (item) {
      case "status":
        this.statusSelectedAll = !this.statusSelectedAll;

        if (this.statusSelectedAll) {
          this.statusFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.statusFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "partner":
        this.partnerSelectedAll = !this.partnerSelectedAll;

        if (this.partnerSelectedAll) {
          this.partnerFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.partnerFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "coachTeam":
        this.coachTeamSelectedAll = !this.coachTeamSelectedAll;

        if (this.coachTeamSelectedAll) {
          this.coachTeamFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.coachTeamFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "shiftTime":
        this.shiftTimeSelectedAll = !this.shiftTimeSelectedAll;

        if (this.shiftTimeSelectedAll) {
          this.shiftTimeFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.shiftTimeFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "shiftDay":
        this.shiftDaySelectedAll = !this.shiftDaySelectedAll;

        if (this.shiftDaySelectedAll) {
          this.shiftDayFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.shiftDayFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "wellnessSurveyGroup":
        this.wellnessSurveyGroupSelectedAll =
          !this.wellnessSurveyGroupSelectedAll;
        this.wellnessSurveyGroupsNoData = false;
        if (this.wellnessSurveyGroupSelectedAll) {
          this.wellnessSurveyGroupFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.wellnessSurveyGroupFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "raceAndEthnicity":
        this.raceAndEthnicitySelectedAll = !this.raceAndEthnicitySelectedAll;

        if (this.raceAndEthnicitySelectedAll) {
          this.raceAndEthnicityFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.raceAndEthnicityFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "orientation":
        this.orientationSelectedAll = !this.orientationSelectedAll;

        if (this.orientationSelectedAll) {
          this.orientationFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.orientationFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "gender":
        this.genderSelectedAll = !this.genderSelectedAll;

        if (this.genderSelectedAll) {
          this.genderFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.genderFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "city":
        this.citySelectedAll = !this.citySelectedAll;

        if (this.citySelectedAll) {
          this.cityFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.cityFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "ages":
        this.agesSelectedAll = !this.agesSelectedAll;

        if (this.agesSelectedAll) {
          this.agesFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.agesFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      default:
        break;
    }
  }
}
