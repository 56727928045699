import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { MindRightService } from './mindright.service';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';
import { 
  loadGenderSelectionsSuccess,
  loadPronounSelectionsSuccess,
  loadSexualOrientationSelectionsSuccess,
  loadRaceSelectionsSuccess,
  loadPartnerSelectionSuccess,
  loadCoachRaceSelectionsSuccess,
  loadCoachGenderSelectionsSuccess,
  loadCoachSexualOrientationSelectionsSuccess
} from 'app/redux/actions/dashboard.actions';

@Injectable()
export class AdminSettingsService {
  constructor(
    private api: ApiService,
    private mrService: MindRightService,
    private ngRedux: NgRedux<IAppState>
  ) {}

  getTimeSlots() {
    const weekdays = {
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
      Sunday: 7
    };
    return this.api.get('/time-slots').pipe(
      map((res: any) => {
        const data = res.data;
        data.sort((a, b) => weekdays[a._id] > weekdays[b._id]);

        return data;
      })
    );
  }

  saveTimeSlot(slot) {
    return this.api.patch('/time-slots/' + slot._id, slot);
  }

  saveAssignation(coachId, students) {
    return this.api.put(`/coaches/${coachId}/students`, { students });
  }

  getAwayMessage() {
    return this.api
      .get('/settings/away-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveAwayMessage(awayMessage: string) {
    return this.api.put('/settings/away-message', { awayMessage });
  }

  getTimeSlotAwayMessage() {
    return this.api
      .get('/settings/time-slot-away-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveTimeSlotAwayMessage(awayMessage: string) {
    return this.api.put('/settings/time-slot-away-message', { awayMessage });
  }

  getGreetingMessage() {
    return this.api
      .get('/settings/greeting-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveGreetingMessage(greetingMessage: string) {
    return this.api.put('/settings/greeting-message', { greetingMessage });
  }

  getEnrollmentMessage() {
    return this.api
      .get('/settings/enrollment-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveEnrollmentMessage(enrollmentMessage: string) {
    return this.api.put('/settings/enrollment-message', { enrollmentMessage });
  }

  getHelpMessage() {
    return this.api
      .get('/settings/help-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveHelpMessage(helpMessage: string) {
    return this.api.put('/settings/help-message', { helpMessage });
  }

  getStartMessage() {
    return this.api
      .get('/settings/start-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveStartMessage(startMessage: string) {
    return this.api.put('/settings/start-message', { startMessage });
  }

  getStopMessage() {
    return this.api
      .get('/settings/stop-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveStopMessage(stopMessage: string) {
    return this.api.put('/settings/stop-message', { stopMessage });
  }

  getInactiveMessage() {
    return this.api
      .get('/settings/inactive-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveInactiveMessage(inactiveMessage: string) {
    return this.api.put('/settings/inactive-message', { inactiveMessage });
  }

  getExitedWhileActiveByAdminMessage() {
    return this.api
      .get('/settings/exited-active-admin-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveExitedWhileActiveByAdminMessage(
    exitedWhileActiveByAdminMessage: string
  ) {
    return this.api.put('/settings/exited-active-admin-message', {
      exitedWhileActiveByAdminMessage
    });
  }

  getExitedByEndOfSubscriptionMessage() {
    return this.api
      .get('/settings/subscription-end-message')
      .pipe(map(res => (res ? res['value'] : '')));
   }

  saveExitedByEndOfSubscriptionMessage(
    exitedByEndOfSubscriptionMessage: string
  ) {
    return this.api.put('/settings/subscription-end-message', {
      exitedByEndOfSubscriptionMessage
    });
  }

  getCaseloadCheckInMessage() {
    return this.api
      .get('/settings/caseload-check-in-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  saveCaseloadCheckInMessage(caseloadCheckInMessage: string) {
    return this.api.put('/settings/caseload-check-in-message', { caseloadCheckInMessage });
  }

  getOnboardingScript() {
    return this.mrService.getOnboardingScript();
  }

  saveOnboardingScript(text: string) {
    return this.mrService.saveOnboardingScript(text);
  }

  getGenderSelections(genderSelectionsId = null) {
    const url = genderSelectionsId
      ? `/settings/gender-selections/${genderSelectionsId}`
      : `/settings/gender-selections`;
    return this.api.get(url).pipe(
      map(res => {
        return res
          ? { id: res['id'], genderSelections: res['genderSelections'] }
          : { id: null, genderSelections: {} };
      })
    );
  }

  getCoachGenderSelections() {
    return this.api.get('/settings/coach-gender-selections').pipe(
      map(res => {
        return res
          ? { id: res['id'], coachGenderSelections: res['coachGenderSelections'] }
          : { id: null, coachGenderSelections: {} };
      })
    );
  }

  getCoachRacesSelections() {
    return this.api.get('/settings/coach-races-selections').pipe(
      map(res => {
        return res
          ? { id: res['id'], coachRaceSelections: res['coachRaceSelections'] }
          : { id: null, coachRaceSelections: {} };
      })
    );
  }

  getCoachSexualOrientationSelections() {
    return this.api.get('/settings/coach-sexual-orientation-selections').pipe(
      map(res => {
        return res
          ? { id: res['id'], coachSexualOrientationSelections: res['coachSexualOrientationSelections'] }
          : { id: null, coachSexualOrientationSelections: {} };
      })
    );
  }

  saveGenderSelections(genderSelections) {
    return this.api.put('/settings/gender-selections', { genderSelections });
  }

  getPronounsSelections(pronounsSelectionsId = null) {
    const url = pronounsSelectionsId
      ? `/settings/pronouns-selections/${pronounsSelectionsId}`
      : `/settings/pronouns-selections`;
    return this.api.get(url).pipe(
      map(res => {
        return res
          ? { id: res['id'], pronounsSelections: res['pronounsSelections'] }
          : { id: null, pronounsSelections: {} };
      })
    );
  }

  savePronounsSelections(pronounsSelections) {
    return this.api.put('/settings/pronouns-selections', {
      pronounsSelections
    });
  }

  saveExitedWhileSignedUpMessage(exitedWhileSignedUpAndInEnrollment) {
    return this.api.put('/settings/set-exited-while-signed-up-and-in-enrollment', {
      exitedWhileSignedUpAndInEnrollment
    })
  }

  saveInactiveWaitListMessage(inactiveWaitListMessage) {
    return this.api.put('/settings/set-inactive-wait-list-message', {
      inactiveWaitListMessage
    })
  }

  pauseInactiveWaitListMessage(isPaused) {
    return this.api.put('/settings/set-inactive-wait-list-paused', {
      isPaused
    })
  }

  pauseExitedWhileSignedUpMessage(isPaused) {
    return this.api.put('/settings/pause-exited-while-signed-up-and-in-enrollment', {
      isPaused
    })
  }

  getExitedWhileSignedUpMessagePausedStatus() {
    return this.api
      .get('/settings/exited-while-signed-up-and-in-enrollment-paused-status')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getExitedWhileSignedUpMessage() {
    return this.api
      .get('/settings/exited-while-signed-up-and-in-enrollment')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getInactiveWaitListMessage() {
    return this.api
      .get('/settings/inactive-wait-list-message')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getSexualOrientationSelections(sexualOrientationSelectionsId = null) {
    const url = sexualOrientationSelectionsId
      ? `/settings/sexual-orientation-selections/${sexualOrientationSelectionsId}`
      : `/settings/sexual-orientation-selections`;
    return this.api.get(url).pipe(
      map(res => {
        return res
          ? {
              id: res['id'],
              sexualOrientationSelections: res['sexualOrientationSelections']
            }
          : { id: null, sexualOrientationSelections: {} };
      })
    );
  }

  saveSexualOrientationSelections(sexualOrientationSelections) {
    return this.api.put('/settings/sexual-orientation-selections', {
      sexualOrientationSelections
    });
  }

  getRacesSelections(racesSelectionsId = null) {
    const url = racesSelectionsId
      ? `/settings/races-selections/${racesSelectionsId}`
      : `/settings/races-selections`;
    return this.api.get(url).pipe(
      map(res => {
        return res
          ? { id: res['id'], racesSelections: res['racesSelections'] }
          : { id: null, racesSelections: {} };
      })
    );
  }

  getPartnerSelections() {
    return this.api.get('/partners').pipe(
      map( res => {
        return res
        ? { partnerSelections: res['data'] }
        : { partnerSelections: [] };
      })
    )
  }

  saveRacesSelections(racesSelections) {
    return this.api.put('/settings/races-selections', { racesSelections });
  }

  getLssScheduleSettings() {
    return this.api.get('/settings/lss-schedule-settings')
      .pipe(map(res => res['value']));
  }

  saveLssScheduleSettings(scheduleSettings) {
    return this.api.put('/settings/lss-schedule-settings', scheduleSettings);
  }

  async loadProfileSections() {
    try {
      const [races, genders, pronouns, orientations, partners] =
        await Promise.all([
          this.getRacesSelections().toPromise(),
          this.getGenderSelections().toPromise(),
          this.getPronounsSelections().toPromise(),
          this.getSexualOrientationSelections().toPromise(),
          this.getPartnerSelections().toPromise(),
        ]);

      this.ngRedux.dispatch(loadRaceSelectionsSuccess(races.racesSelections));
      this.ngRedux.dispatch(
        loadGenderSelectionsSuccess(genders.genderSelections)
      );
      this.ngRedux.dispatch(
        loadPronounSelectionsSuccess(pronouns.pronounsSelections)
      );
      this.ngRedux.dispatch(
        loadSexualOrientationSelectionsSuccess(
          orientations.sexualOrientationSelections
        )
      );
      this.ngRedux.dispatch(
        loadPartnerSelectionSuccess(partners.partnerSelections)
      );
    } catch (error) {
      console.error("Error loading profile sections:", error);
    }
  }

  async loadCoachProfileSelections() {
    try {
      const [races, genders, orientations] = await Promise.all([
        this.getCoachRacesSelections().toPromise(),
        this.getCoachGenderSelections().toPromise(),
        this.getCoachSexualOrientationSelections().toPromise(),
      ]);

      this.ngRedux.dispatch(loadCoachRaceSelectionsSuccess(races.coachRaceSelections));
      this.ngRedux.dispatch(loadCoachGenderSelectionsSuccess(genders.coachGenderSelections));
      this.ngRedux.dispatch(loadCoachSexualOrientationSelectionsSuccess(orientations.coachSexualOrientationSelections));
    } catch (error) {
      console.error("Error loading coach profile selections:", error);
    }
  }

  saveInactiveForTenDaysStatus(inactiveForTenDays) {
    return this.api.put('/settings/set-inactive-for-ten-days', {
      inactiveForTenDays
    })
  }

  saveExitedInactiveNoResponse(exitedInactiveNoResponse) {
    return this.api.put('/settings/set-exited-inactive-no-response', {
      exitedInactiveNoResponse
    })
  }

  saveMindrightMinutes(mindrightMinutes) {
    return this.api.put('/settings/set-mindright-minutes', {
      mindrightMinutes
    })
  }

  pauseInactiveForTenDaysStatus(isPaused) {
    return this.api.put('/settings/pause-inactive-for-ten-days', {
      isPaused 
    })
  }

  pauseExitedInactiveNoResponse(isPaused) {
    return this.api.put('/settings/pause-exited-inactive-no-response', {
      isPaused 
    })
  }

  pauseMindrightMinutes(isPaused) {
    return this.api.put('/settings/pause-mindright-minutes', {
      isPaused 
    })
  }

  getInactiveForTenDaysPausedStatus() {
    return this.api
      .get('/settings/inactive-for-ten-days-status')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getExitedInactiveNoResponsePausedStatus() {
    return this.api
      .get('/settings/exited-inactive-no-response-status')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getMindrightMinutesPausedStatus() {
    return this.api
      .get('/settings/mindright-minutes-status')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getInactiveForTenDays() {
    return this.api
      .get('/settings/inactive-for-ten-days')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getExitedInactiveNoResponse() {
    return this.api
      .get('/settings/exited-inactive-no-response')
      .pipe(map(res => (res ? res['value'] : '')));
  }

  getMindrightMinutes() {
    return this.api
      .get('/settings/mindright-minutes')
      .pipe(map(res => (res ? res['value'] : '')));
  }
  
  getInactiveWaitListPausedStatus() {
    return this.api
      .get('/settings/inactive-wait-list-paused')
      .pipe(map(res => (res ? res['value'] : '')));
  }

}
