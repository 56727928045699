<header class="header mat-elevation-z1">
  <div class="header-left">
    <!-- <h1>MindRight</h1> -->
    <img fxFlexAlign="center" src="assets/images/mr-logo.png" style="width: 45px">
    <span *ngIf="!isProductionEnvironment" class="environment">{{environmentName || 'Local'}}</span>
    <span class="phone">886-886</span>
  </div>

  <div class="header-right">
    <span class="user-name" *ngIf="app.currentUser" routerLink="/account">{{app.currentUser.firstName}}</span>
    <span class="user-role">{{userTitle}}</span>
    <!-- <button mat-button>
      <i class="fa fa-bell" style="font-weight: 100"></i>
    </button> -->

    <mat-menu #appMenu="matMenu">
      <button mat-menu-item
        *ngIf="auth.isAdminOrAnalyst() || auth.isUserLss() || auth.isCurrentUserCoach() || auth.isUserManager()"
        routerLink="/settings"><i class="fa fa-cog me-2"></i> Settings</button>
      <button mat-menu-item routerLink="/account"><i class="fa fa-user me-2"></i> Account</button>
      <button mat-menu-item (click)="logout()"><i class="fa fa-sign-out-alt me-2"></i> Logout</button>
    </mat-menu>

    <mat-menu #crisisMembersMenu="matMenu" class="crisis-menu">
      <ul *ngIf="crisisTextsMembers.length" class="list-group">
        <li *ngFor="let member of crisisTextsMembers" class="list-group-item crisis-list-group">
          <p class="d-inline-block mb-0 icon-text">{{ member._user | displayStudentName }}</p>
          <i class="fa fa-comments fa-fw chat-icon chat-icon__concern" (click)="goToChat(member._user._id)"></i>
        </li>
      </ul>
    </mat-menu>

    <button class="position-relative" *ngIf="userTitle == 'Lss'" mat-button [disabled]="!crisisTextsMembers.length"
      disableRipple="true" [matMenuTriggerFor]="crisisMembersMenu">
      <mat-icon>notifications</mat-icon>
      <p class="counter" *ngIf="crisisTextsMembers.length">
        <span>{{ crisisTextsMembers.length }}</span>
      </p>
    </button>

    <button mat-button disableRipple="true" [matMenuTriggerFor]="appMenu">
      <i class="fa fa-user" style="font-weight: 100"></i>
    </button>
    <a href="https://mindright.atlassian.net/servicedesk/customer/portal/1" target="_new" class="support-link">
      <i class="fa fa-question-circle" style="font-size: 14px;font-weight: 100;"></i>
      Support
    </a>

    <div
      *ngIf="displayCheckIn && auth.isCurrentUserCoach() && (! auth.isCurrentUserLss() && !auth.isCurrentUserAdmin() && !auth.isUserManager())">
      <button mat-raised-button [disabled]="runCaseloadCheckInLoading" (click)="onClickCheckIn()" color="primary"
        class="mb-3 mt-3">Check In</button>
    </div>
  </div>
</header>