import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";

import { GetMemberRatingHistoryResponse } from "app/types";
import { ApiService } from "./api.service";

@Injectable()
export class MemberCoachingSessionService {
  constructor(private api: ApiService) {}

  getMemberRatingHistory(
    memberId: string,
    pageSize: number,
    pageNumber: number
  ): Observable<GetMemberRatingHistoryResponse> {
    return this.api
      .get(
        `/member-coaching-sessions/${memberId}/rating-history?pageNumber=${pageNumber}&pageSize=${pageSize}`
      )
      .pipe(
        map((res: any) => {
          if (res.data.status !== 200) {
            throw new Error(res.message || "Unknown error occurred");
          }
          return res.data as GetMemberRatingHistoryResponse;
        }),
        catchError((error) => throwError(error))
      );
  }

  getCoachRatingMetrics(coachId: string) {
    return this.api.get(`/member-coaching-sessions/${coachId}/coach-ratings`)
      .pipe(
        map((res) => res['data']),
        catchError((error) => throwError(error))
      );
  }

  getCoachSessionRecords(coachId: string, pageNumber: number, pageSize: number) {
    return this.api.get(`/member-coaching-sessions/${coachId}/coach-sessions?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .pipe(
        map((res) => res['data']),
        catchError((error) => throwError(error))
      );
  }

  getAverageSessionRatings() {
    return this.api.getData('/member-coaching-sessions/average-ratings')
      .pipe(map((res) => res['data']));
  }

  getCoachedMembersCount() {
    return this.api.getData('/member-coaching-sessions/members-coached')
      .pipe(map((res) => res['data']));
  }
}
