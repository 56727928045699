import { Pipe, PipeTransform } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from 'app/redux/store';

@Pipe({ name: 'displayGender' })
export class DisplayGenderPipe implements PipeTransform {

  constructor(private ngRedux: NgRedux<IAppState>) { }

  transform(gender = '', args: string[]) {
    const genderSelections = this.ngRedux.getState().entities.dashboard.profileSelections['gender'];

    if (Object.keys(genderSelections).includes(gender)) {
      return genderSelections[parseInt(gender, 10)];
    } else {
      switch (gender) {
        case 'M': return 'Male';
        case 'F': return 'Female';
        case 'T': return 'Transgender';
        case 'GQ': return 'Gender Queer';
        case '3': return 'Male';
        case '2': return 'Female';
        case '1': return 'Non-binary';
        default: return '';
      }
    }
  }
}
