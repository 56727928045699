<div *ngIf="!authService.isCurrentUserCoach() && !authService.isUserManager() && authService.isCurrentUserNotAnalyst()" style="padding: 10px;">
<ng-template #timeSlotModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Assign Coaches to
      <i>{{editSlot.start}} - {{editSlot.end}} on {{editSlot.day}}s</i>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form">

      <div class="form-group">
        <label>Assign Coaches</label>
        <ng-select [multiple]="true" placeholder="Select coaches" [(ngModel)]="editSlot._coaches">
          <ng-option [value]="coach._id" *ngFor="let coach of coaches">{{coach.firstName}} {{coach.lastName}}
          </ng-option>
        </ng-select>
      </div>

      <div class="form-group">
        <label>Assign Members</label>
        <ng-select [multiple]="true" placeholder="Select students" [(ngModel)]="editSlot._students">
          <ng-option class="fs-exclude" [value]="student._id" *ngFor="let student of students">{{student.firstName}} {{student.lastName}}
          </ng-option>
        </ng-select>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <div (click)="c('Cancel')" class="btn btn-secondary">Cancel</div>
    <div (click)="saveSlot(editSlot)" class="btn btn-success">
      Save
      <i class="fa fa-check"></i>
    </div>
  </div>
</ng-template>

<ng-template #assignStudentModal let-c="close" let-d="dismiss">
  <!-- <div class="modal-header">
    <h4 class="modal-title">Assign Members to {{editCoach.firstName}} {{editCoach.lastName}}</h4>
    <button mat-icon-button class="close" aria-label="Close" (click)="c('Cross click')">
      <span aria-hidden="true">&times;</span>
      <mat-icon>close</mat-icon>
    </button>
  </div> -->
  <mat-toolbar>
    <mat-toolbar-row>
      <span>Assign Members to {{editCoach.firstName}} {{editCoach.lastName}}</span>
      <span class="flex-spacer"></span>
      <button mat-icon-button color="primary" class="close" aria-label="Close" matTooltip="Close"
        (click)="c('Cross click')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="modal-body">
    <div class="form row mb-3">

      <!-- <div class="form-group">
        <label>Assign Members</label>

        <ng-select *ngIf="editCoach?._data" [multiple]="true" placeholder="Select students..."
          [(ngModel)]="editCoach._data._students">
          <ng-option [value]="student._id" *ngFor="let student of students">{{student.firstName}} {{student.lastName}}
          </ng-option>
        </ng-select>
      </div> -->
      <mat-form-field appearance="fill" *ngIf="editCoach?._data">
        <mat-label>Assign Members</mat-label>
        <mat-select [(ngModel)]="editCoach._data._students" multiple>
          <mat-form-field style="width: 100%;" appearance="fill">
            <mat-label>Filter Members</mat-label>
            <input matInput type="text" [(ngModel)]="memberFilterText" (keyup)="onKeyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="Clear" (click)="onKeyFilter('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-option *ngFor="let member of filteredMembers" [value]="member._id">
            {{member.firstName}} <span class="fs-exclude">{{member.lastName}}</span>
          </mat-option>
        </mat-select>
        <!-- <ng-select [multiple]="true" placeholder="Select students" [(ngModel)]="editSlot._students">
          <input placeholder="Filter Members" matInput type="text" (keyup)="filterMembers($event.target.value)">
          <ng-option [value]="student._id" *ngFor="let student of filteredMembers">{{student.firstName}} {{student.lastName}}
          </ng-option>
        </ng-select> -->
      </mat-form-field>

    </div>
    <div class="alert alert-warning">
      <mat-icon>help_outline</mat-icon>
      Members won't appear until they are named, first name and last name must be set
    </div>

  </div>
  <div class="modal-footer">
    <button mat-raised-button (click)="c('Cancel')" class="me-2">Cancel</button>
    <button mat-raised-button color="accent" (click)="saveAssignation(editCoach)">
      <mat-icon>check</mat-icon>
      Save
    </button>
  </div>
</ng-template>

<!-- Loading Component -->
<div class="content-loading" *ngIf="allLoading">
  <mat-progress-bar mode="buffer" value={{loadingProgress}}></mat-progress-bar>
  <mat-card [class.mat-elevation-z0]="disableShadow" class="loading-card">
    <mat-card-content fxLayout="column wrap">
      <h1 style="margin-bottom: 46px;">Fetching Coach & Member Data</h1>
      <h3 style="text-align: center; margin-bottom: 24px;">Please wait<span
          style="color: #3458B2;">{{currentLoadingItem}}</span>...</h3>
      <h5 style="text-align: center;">{{loadingProgress}}% completed</h5>
    </mat-card-content>
  </mat-card>
</div>

<div fxFlex *ngIf="!allLoading">
  <div fxFlex fxLayout="row" fxLayoutGap="10px">
    <div fxFlex *ngIf="!authService.isUserLss()">

      <div class="card">
        <div class="card-header">Manage timeslots</div>

        <div class="card-body">
          <ngb-accordion [closeOthers]="true">
            <ngb-panel *ngFor="let day of timeSlots" [title]="day._id + 's'">

              <ng-template ngbPanelContent>
                <div style="margin-bottom: 10px" *ngFor="let slot of day.slots">
                  <button (click)="openSlotModal(slot)" class="btn btn-sm btn-dark">Edit</button>

                  <label>{{slot.start}} - {{slot.end}} {{slot.day}}</label>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>

      <div class="card" style="margin-top: 16px;" *ngIf="authService.isCurrentUserAdmin()">
        <div class="card-header">Generate Reports</div>

        <div class="card-body">
          <div class="row assignment-btn-row" *ngIf="authService.isUserSuperAdmin()">
            <div *ngIf="monthlyReportForBillingLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="accent" [disabled]="monthlyReportForBillingLoading"
                (click)="runMonthlyReportForBilling()" class="">Run Monthly Report For Billing</button>
            </div>
          </div>

          <!--NPS Survey Button Trigger-->
          <div class="row assignment-btn-row" *ngIf="authService.isUserSuperAdmin()">
            <div *ngIf="NPSSurveyLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="warn" [disabled]="NPSSurveyLoading" (click)="openNPSDialog()"
                class="">Run NPS Survey Administration</button>
            </div>
          </div>

          <!--SWEMWBS + SIAOS Survey Button Trigger-->
          <div class="row assignment-btn-row" *ngIf="authService.isUserSuperAdmin()">
            <div *ngIf="SWEMWBSSurveyLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="warn" [disabled]="SWEMWBSSurveyLoading" (click)="openSWEMWBSDialog()"
                class="">Run SWEMWBS Survey Administration</button>
            </div>
          </div>

          <!--Prolonged Negative Sentiment Trigger-->
          <div class="row assignment-btn-row" *ngIf="authService.isUserSuperAdmin()">
            <div *ngIf="negativeSentimentLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="accent" [disabled]="negativeSentimentLoading"
                (click)="runNegativeSentiment()" class="">Compute Prolonged Negative Sentiment</button>
            </div>
          </div>

          <!-- Transition Coach Statuses -->
          <div class="row assignment-btn-row" *ngIf="authService.isCurrentUserAdmin()">
            <div *ngIf="transitionCoachStatusLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="accent" [disabled]="transitionCoachStatusLoading"
                (click)="runCoachStatusTransition()" class="">Run Coach Status Transitions</button>
            </div>
          </div>

          <!-- Run Wellness Data Upload -->
          <div class="row assignment-btn-row" *ngIf="authService.isUserSuperAdmin()">
            <div *ngIf="wellnessDataUploadLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="warn" [disabled]="wellnessDataUploadLoading"
                (click)="openWellnessUploadDialog()" class="">Run Wellness Data Upload</button>
            </div>
          </div>

          <!-- Run Wellness Data Follow Ups -->
          <div class="row assignment-btn-row" *ngIf="authService.isUserSuperAdmin()">
            <div *ngIf="wellnessDataFollowUpsLoading" class="col">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col">
              <button mat-raised-button color="warn" [disabled]="wellnessDataFollowUpsLoading"
                (click)="openWellnessFollowUpsDialog()" class="">Run Wellness Data Follow Ups</button>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div fxFlex>

      <div class="card">
        <div class="card-header">Manage Coaches</div>

        <div class="card-body">
          <div class="row assignment-btn-row">
            <div class="col-sm">
              <button mat-raised-button color="accent" [disabled]="runAssignmentsLoading || clearAssignmentsLoading"
                (click)="runAssignments()" class="">Assign</button>
            </div>
            <div *ngIf="runAssignmentsLoading" class="col-sm">
              <div class="spinner-grow text-success" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div *ngIf="clearAssignmentsLoading" class="col-sm">
              <div class="spinner-grow text-danger" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="col-sm">
              <button mat-raised-button color="warn" [disabled]="runAssignmentsLoading || clearAssignmentsLoading"
                (click)="clearAssignments()" class="">Clear</button>
            </div>
          </div>

          <div class="list-group">
            <div *ngFor="let coach of coaches" class="list-group-item">

              <div (click)="openAssignModal(coach)" class="btn btn-sm btn-dark float-end">Assign students</div>
              <div class="">
                {{coach.firstName}} {{coach.lastName}}
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
</div>
