import * as toastr from 'toastr';

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';


import * as FullStory from '@fullstory/browser';

import { AppService } from 'app/core/services/app.service';
import { AuthService } from 'app/core/services/auth.service';
import { SocketIOService } from 'app/core/services/socket.service';
import { ConversationService } from 'app/core/services/conversation.service';

import { StudentProfile } from './apollo/coach/coach.apollo.types';
import { concat } from 'rxjs/operators';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  coachAssignedStudents: Observable<StudentProfile[]>;
  isCoach = this.auth.isCurrentUserCoach();
  // refreshSessionModal;
  // @ViewChild('refreshSessionModal') refreshSessionModalContent;

  refreshUser: any = {};

  showSplashLoadingLogo = false;
  showRequestSpinner = false;

  appLoading = true
  loadingProgress = 0
  currentLoadingItem = ''

  constructor(
    public app: AppService,
    private auth: AuthService,
    private router: Router,
    private socketIOService: SocketIOService,
    conversation: ConversationService
  ) {
    FullStory.init({ orgId: 'F3F4F', devMode: false });

    auth.isLoggedIn()
      .pipe(concat(auth.isAuthenticated$))
      .subscribe(loggedIn => {
        if (loggedIn) {
          conversation.init();
        } else {
          this.appLoading = false
        }
      });

    socketIOService.socketStatus$.pipe().subscribe(status => {
      if (status.isOpen && status.hasDisconnected) {
        conversation.refresh();
      }
    });

    app.onUnauthorisedResponse$.subscribe(() => {
      this.router.navigate(['/login']);
    });

    app.onShowSplashLoading$.subscribe(showLoading => {
      this.showSplashLoadingLogo = showLoading
    });

    app.onShowRequestSpinner$.subscribe(showRequestSpinner => {
      Promise.resolve().then(() => this.showRequestSpinner = showRequestSpinner);
    });

    conversation.onUpdateInitLoad$.subscribe(data => {
      this.loadingProgress = data.percentage
      this.currentLoadingItem = data.message

      if (data.complete) {
        this.appLoading = false
        this.loadingProgress = 0
        this.currentLoadingItem = ''
      }
    })
  }

  ngOnInit() {
    let offlineTitleString = `
    <span style="color: white; font-weight: normal">
      <mat-icon
        class="mat-icon material-icons mat-icon-no-color me-2"
        role="img" style="line-height: 28px; vertical-align: bottom;"
        aria-hidden="true">
        wifi_off
      </mat-icon>
      Offline
    </span>`;

    this.socketIOService.socketStatus$.subscribe(onlineStatus => {
      if (onlineStatus.isOpen) {
        toastr.clear();
      } else if (!onlineStatus.isOpen && this.app.isLoggedIn) {
        toastr.clear();
        toastr.error(
          'Something\'s wrong, please check your internet connection',
          offlineTitleString,
          {
            timeOut: 0,
            extendedTimeOut: 0,
            tapToDismiss: false
          }
        );
      }
    });
  }

  // showRefreshAuthModal() {
  //   if (this.refreshSessionModal) {
  //     return ;
  //   }

  //   this.refreshSessionModal = this.modalService.open(this.refreshSessionModalContent, {
  //     size: 'sm',
  //     backdrop: 'static',
  //     keyboard: false
  //   });
  // }

  // hideRefreshModal() {
  //   this.refreshSessionModal.close();
  //   this.refreshSessionModal = null;
  // }

  // onSubmit() {
  //   this.auth.login(this.refreshUser.email, this.refreshUser.password)
  //     .subscribe(
  //       () => this.hideRefreshModal(),
  //       err => this.notificationService.alertError(err)
  //     );
  // }

  onLogoutClick() {
    this.auth.logout();
    this.router.navigate(['/login']);
    this.socketIOService.socketStatus$.unsubscribe()
    // this.hideRefreshModal();
  }
}
