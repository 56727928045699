import { Injectable } from '@angular/core';

@Injectable()
export class SendbirdWidgetService {
  private readonly APP_ID = 'SENDBIRD_APP_ID';

  constructor() {}

  initializeWidget(userId: string, nickname: string): void {
    if ((window as any).SendBirdWidget) {
      (window as any).SendBirdWidget.init({
        appId: this.APP_ID,
        userId: userId,
        nickname: nickname,
        onSuccess: () => console.log('Sendbird widget initialized'),
        onError: (error: any) => console.error('Sendbird widget failed to initialize', error),
      });
    } else {
      console.error('Sendbird Widget SDK is not loaded');
    }
  }
}
