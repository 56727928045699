import { map, filter, take } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { NgRedux } from "@angular-redux/store";
import { IAppState } from "app/redux/store";
import {
  GetLeadByTypeResponse,
  IMemberSatisfactionRateHistoryResponse,
  IPartnerTransitionResponse,
  IStatusTransitioningHistory,
  IncidentReport,
  IncidentReportResponse,
  LeadUploadUrl,
  UploadLeadResponse,
} from "app/types";
import {
  loadUserSuccess,
  addUserSuccess,
} from "app/redux/actions/user.actions";
import {
  addStudentSuccess,
  loadStudentsSuccess,
  deleteStudentSuccess,
  updateStudentSuccess,
  updateStudentAwayMessageSuccess,
  updateStudentEscalatedModeSuccess,
} from "app/redux/actions/student.actions";

import { ApiService } from "./api.service";
import { AppService } from "./app.service";
import { SocketIOService } from "./socket.service";
import { ConversationService } from "./conversation.service";
import { UploadApiService } from "./upload-api.service";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class StudentService {
  constructor(
    private api: ApiService,
    private uploadApi: UploadApiService,
    private app: AppService,
    private rxsoc: SocketIOService,
    private ngRedux: NgRedux<IAppState>,
    private conversationService: ConversationService
  ) {
    // this.loadStudents();
  }

  createStudent(student) {
    return this.api.post("/students", student).pipe(
      map((res) => {
        let newStudent = res;
        this.ngRedux.dispatch(addUserSuccess(newStudent));
        this.ngRedux.dispatch(addStudentSuccess(newStudent));
        return newStudent;
      })
    );
  }

  createStudents(student) {
    return this.api.post("/students?multiple=1", student).pipe(
      map((res: any) => {
        let data = res;

        data.forEach((studentData) => {
          if (studentData.success) {
            this.ngRedux.dispatch(addUserSuccess(studentData.student));
            this.ngRedux.dispatch(addStudentSuccess(studentData.student));
          }
        });
        return data;
      })
    );
  }

  getStudent(id: string, force = false) {
    this.loadStudent(id, force);
    return this.ngRedux
      .select((state) => state.entities.users.byId[id])
      .pipe(filter((v) => v));
  }

  async getMember(memberId: string, force = false) {
    let dispatch = this.ngRedux.dispatch;
    let user = this.ngRedux.getState().entities.users.byId[memberId];

    if (user && !force) {
      return user;
    }
    if (!user || force === true) {
      const res = await this.api.get(`/students/${memberId}`).toPromise();
      dispatch(loadUserSuccess(res));

      return res;
    }
  }

  loadStudent(id: string, force = false) {
    let dispatch = this.ngRedux.dispatch;
    let user = this.ngRedux.getState().entities.users.byId[id];
    if (user && !force) {
      return dispatch(loadUserSuccess(user));
    }
    if (!user || force === true) {
      this.api.get(`/students/${id}`).subscribe((res) => {
        dispatch(loadUserSuccess(res));
      });
    }
  }

  loadStatusTransitioning(
    id: string,
    pageSize = 5,
    pageNumber = 0
  ): Observable<IStatusTransitioningHistory> {
    return this.api.getData<IStatusTransitioningHistory>(
      `/students/${id}/status-transitioning?pageSize=${pageSize}&pageNumber=${pageNumber}`
    );
  }

  getStudents() {
    return this.ngRedux
      .select((s) => {
        if (!("studentsIds" in s.entities.users)) {
          return [];
        }
        if (!s.entities.users.studentsIds.length) return [];
        return (
          s.entities.users.studentsIds.map((i) => s.entities.users.byId[i]) ||
          []
        );
      })
      .pipe(take(1));
  }

  getSlotsForToday(id: string) {
    return this.api.get("/students/" + id + "/slots-today");
  }

  async loadStudents() {
    try {
      const res: any = await this.api.get("/students").toPromise();
      if ("members" in res.data) {
        this.ngRedux.dispatch(loadStudentsSuccess(res.data.members));
      }
    } catch (error) {
      console.error(error);
    }
  }

  loadStudentsWithHandles(
    limit = 30,
    page = 0,
    searchString,
    ensureName = true,
    recentlyAdded = false
  ) {
    return this.api.get(
      `/students?limit=${limit}&page=${page}&search=${searchString}&ensureName=${ensureName}&recentlyAdded=${recentlyAdded}`
    );
  }

  updateStudent(id: string, data: any) {
    return this.api.patch("/students/" + id, data).pipe(
      map((res: any) => {
        const student = res;
        delete student.password;
        this.ngRedux.dispatch(updateStudentSuccess(student));
        return student;
      })
    );
  }

  updateEngagementStatus(id: string, data: any) {
    return this.api.patch("/students/engagement-status/" + id, data).pipe(
      map((res: any) => {
        const student = res;
        return student;
      })
    );
  }

  getStatusesOptions() {
    return this.api.get("/statuses/options").pipe(
      map((res: any) => {
        const statusOptions = res;
        return statusOptions;
      })
    );
  }

  transitionStudentStatus(reqBody: any) {
    return this.api.put(`/statuses/transition-status`, { reqBody }).pipe(
      map((res: any) => {
        const student = res;
        delete student.password;
        this.ngRedux.dispatch(updateStudentSuccess(student));
        return student;
      })
    );
  }

  transitionStudentPartner(studentId: String, partnerId: any) {
    return this.api
      .put(`/PartnerTransitions/transition-partner`, {
        studentId,
        partnerId,
      })
      .pipe(
        map((res: any) => {
          const student = res;
          delete student.password;
          this.ngRedux.dispatch(updateStudentSuccess(student));
          return student;
        })
      );
  }

  getPartnerTransitions(
    studentId: String,
    pageSize = 5,
    pageNumber = 0
  ): Observable<IPartnerTransitionResponse> {
    return this.api
      .getData<IPartnerTransitionResponse>(
        `/PartnerTransitions/allTransitions/${studentId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getMemberSatisfactoryRate(
    id,
    pageSize,
    pageNumber,
    element,
    order
  ): Observable<IMemberSatisfactionRateHistoryResponse> {
    return this.api.getData<IMemberSatisfactionRateHistoryResponse>(
      `/students/satisfaction-rate-history/${id}?pageSize=${pageSize}&pageNumber=${pageNumber}&element=${element}&order=${order}`
    );
  }

  exitStudent(id: string) {
    return this.api.patch(`/students/${id}/exit`).pipe(
      map((res: any) => {
        const student = res.updatedStudent;
        delete student.password;
        this.ngRedux.dispatch(updateStudentSuccess(student));
        return student;
      })
    );
  }

  updateStudentAwayMessage(id: string, isAwayMessageOn: boolean) {
    return this.api
      .patch(`/students/${id}/away-message`, { isAwayMessageOn })
      .pipe(
        map((res) => {
          this.ngRedux.dispatch(
            updateStudentAwayMessageSuccess(id, isAwayMessageOn)
          );
          return res;
        })
      );
  }

  updateStudentEscalatedMode(id: string, isEscalated) {
    return this.api.patch(`/students/${id}/escalated`, { isEscalated }).pipe(
      map((res) => {
        this.ngRedux.dispatch(
          updateStudentEscalatedModeSuccess(id, isEscalated)
        );
        return res;
      })
    );
  }

  mergeStudent(intoStudentId, fromStudentId) {
    return this.api
      .patch("/students/" + intoStudentId + "/merge/" + fromStudentId)
      .pipe(
        map((res) => {
          this.conversationService.refresh();
          this.loadStudents();
          return res;
        })
      );
  }

  deleteStudent(id: string) {
    return this.api.delete("/students/" + id).pipe(
      map((res) => {
        this.ngRedux.dispatch(deleteStudentSuccess(id));
        return res;
      })
    );
  }

  getStudentsLogs() {
    return this.api.get("/students-logs");
  }

  getBaseFilterOptions() {
    return [
      "City",
      "Partner",
      "Gender",
      "Age",
      "Race",
      "Orientation",
      "Timeslot",
    ];
  }

  getBaseFilterOptionsFunctions() {
    return {
      City: this.getCityOptions,
      Partner: this.getPartnerOptions,
      Gender: this.getGenderOptions,
      Race: this.getRaceOptions,
      Orientation: this.getOrientationOptions,
    };
  }

  getFilterOptions(filter) {
    const optionFunction = this.getBaseFilterOptionsFunctions()[filter];
    if (optionFunction) {
      return optionFunction();
    }
  }

  getCityOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s.city);
        return Array.from(new Set(options));
      })
    );
  };

  getStateOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s.state);
        return Array.from(new Set(options));
      })
    );
  };

  getPartnerOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s._data.primaryPartnerName);
        return Array.from(new Set(options));
      })
    );
  };

  getGenderOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s.gender);
        return Array.from(new Set(options));
      })
    );
  };

  getRaceOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s._data.race);
        return Array.from(new Set(options));
      })
    );
  };

  getOrientationOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s._data.sexualOrientation);
        return Array.from(new Set(options));
      })
    );
  };

  getSchoolOptions = () => {
    return this.getStudents().pipe(
      map((students) => {
        const options = students.map((s) => s._data?.school);
        return Array.from(new Set(options));
      })
    );
  };

  runProlongedNegativeSentiment() {
    return this.api.post("/students/run-prolonged-negative-sentiment");
  }

  getTotalTextsMetrics(memberId, startDate, endDate) {
    return this.api.get(
      `/students/${memberId}/${startDate}/${endDate}/metrics-get-total-texts`
    );
  }

  getTotalRetention(memberId) {
    return this.api.get(`/students/${memberId}/metrics-member-retention`);
  }

  getMemberTextResponseRateMetrics(memberId, startDate, endDate) {
    return this.api.get(
      `/students/${memberId}/${startDate}/${endDate}/metrics-text-response-rate`
    );
  }

  setMemberEngaged(memberId, engaged = false) {
    return this.api.patch(`/students/${memberId}/set-member-engaged`, {
      engaged,
    });
  }

  setEOSStatus(memberId, status = false) {
    return this.api.patch(`/coaches/${memberId}/eosmessage`, { status });
  }

  getAllMembers() {
    return this.api.get("/students");
  }

  getIncidentReports(
    studentId: String,
    pageSize = 5,
    pageNumber = 0
  ): Observable<IncidentReportResponse> {
    return this.api
      .getData<IncidentReportResponse>(
        `/incident-reports/${studentId}?pageSize=${pageSize}&pageNumber=${pageNumber}`
      )
      .pipe(map((res: any) => res));
  }

  getAllIncidentReports(
    memberIds: string[],
    startDate = "",
    endDate = ""
  ): Observable<IncidentReport[]> {
    return this.api
      .post("/incident-reports/all", { startDate, endDate, members: memberIds })
      .pipe(map((res) => res["data"]));
  }

  getMemberCount(params = null) {
    const queryString = params ? new HttpParams({ fromObject: params }).toString() : '';
    const url = queryString ? `/students/count?${queryString}` : '/students/count';
    return this.api.getData(url).pipe(map((res) => res["data"]));
  }

  bulkCreateStudent(data) {
    return this.api.post("/students/bulk", { members: data }).pipe(
      map((res: any) => ({ succesful: res.succesful, failed: res.failed }))
    );
  }
}
