import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { QuicklinkModule } from 'ngx-quicklink';
import { CountdownModule } from 'ngx-countdown';

import { DisplayStudentNamePipe } from 'app/shared/pipes/display-student-name.pipe';
import { DisplayStudentLastNamePipe } from 'app/shared/pipes/display-student-lastName.pipe';
import { FormatPhoneNumberPipe } from 'app/shared/pipes/format-phone-number.pipe';
import { MomentFormatPipe } from 'app/shared/pipes/moment-format.pipe';
import { ScheduleMomentFormatPipe } from 'app/shared/pipes/schedule-moment.pipe';
import { BypassSecurityPipe } from 'app/shared/pipes/bypass-security.pipe';
import { DisplayGenderPipe } from 'app/shared/pipes/display-gender.pipe';
import { DisplayPronounsPipe } from 'app/shared/pipes/display-pronouns.pipe';
import { DisplayOrientationPipe } from 'app/shared/pipes/display-orientation.pipe';
import { DisplayRacePipe } from 'app/shared/pipes/display-race.pipe';
import { ComponentFnPipe } from 'app/shared/pipes/component-function.pipe';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MaterialModule } from '../material.module';
import { QuillModule } from 'ngx-quill';
import { CustomQuillModule } from './customqull.module';

import { HeaderComponent } from 'app/shared/components/header/header.component';
import { MessageComponent } from 'app/shared/components/message/message.component';
import { FilterComponent } from 'app/shared/components/filter/filter.component';
import { AssessmentAnalyticsComponent } from 'app/shared/components/assessment-analytics/assessment-analytics.component';
import { PasswordValidateComponent } from 'app/shared/components/password-validate/password-validate.component';
import { EditableHtmlComponent } from './components/editable-html/editable-html.component';
import { RiskAlertsTableComponent } from './components/risk-alerts/risk-alerts-table.component';
import { RiskAlertDialogComponent } from './modals/risk-alert-dialog/risk-alert-dialog.component';
import { RiskAlertDetailsDialogComponent } from './modals/risk-alert-details-dialog/risk-alert-details-dialog.component';
import { MemberFiltersComponent } from './components/member-filters/member-filters.component';
import { LeadFiltersComponent } from './components/lead-filters/lead-filters.component';


@NgModule({
  entryComponents: [
    RiskAlertDialogComponent,
    RiskAlertDetailsDialogComponent
  ],
  declarations: [
    HeaderComponent,
    MessageComponent,
    EditableHtmlComponent,
    PasswordValidateComponent,
    DisplayStudentNamePipe,
    DisplayStudentLastNamePipe,
    FormatPhoneNumberPipe,
    MomentFormatPipe,
    ScheduleMomentFormatPipe,
    BypassSecurityPipe,
    DisplayGenderPipe,
    DisplayPronounsPipe,
    DisplayOrientationPipe,
    DisplayRacePipe,
    FormatPhoneNumberPipe,
    ComponentFnPipe,
    FilterComponent,
    AssessmentAnalyticsComponent,
    RiskAlertDialogComponent,
    RiskAlertDetailsDialogComponent,
    RiskAlertsTableComponent,
    MemberFiltersComponent,
    LeadFiltersComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    QuillModule.forRoot(),
    VirtualScrollerModule,
    ReactiveFormsModule,
    CustomQuillModule,
    // NgxDatatableModule,
    MaterialModule,
    QuicklinkModule,
    CountdownModule,
    ClipboardModule
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    AssessmentAnalyticsComponent,
    PasswordValidateComponent,
    FilterComponent,
    EditableHtmlComponent,
    RiskAlertsTableComponent,
    NgbModule,
    MessageComponent,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgSelectModule,
    DisplayStudentNamePipe,
    DisplayStudentLastNamePipe,
    FormatPhoneNumberPipe,
    MomentFormatPipe,
    ScheduleMomentFormatPipe,
    BypassSecurityPipe,
    FormatPhoneNumberPipe,
    DisplayGenderPipe,
    DisplayRacePipe,
    DisplayPronounsPipe,
    DisplayOrientationPipe,
    ComponentFnPipe,
    VirtualScrollerModule,
    QuillModule,
    MaterialModule,
    // NgxDatatableModule,
    MemberFiltersComponent,
    LeadFiltersComponent,
    QuicklinkModule,
    CountdownModule,
    ClipboardModule
  ]
})
export class SharedModule {}
