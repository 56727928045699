import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import moment from 'moment-timezone'
import { PartnerService } from "app/core/services/partner.service";
import { map } from "rxjs/operators";
import { LeadFilter } from './lead-filters.constant';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { LeadService } from 'app/core/services/lead.service';

@Component({
  selector: 'lead-filters',
  templateUrl: './lead-filters.component.html',
  styleUrls: ['./lead-filters.component.scss']
})
export class LeadFiltersComponent implements OnInit {
  @Input() disableShadow = false;
  @Input() showDateRange = false;
  @Input() showCity = false;
  @Input() showState = false;
  @Input() showName = false;
  @Input() showPartner = false;
  @Input() showZipCode = false;
  @Input() showGender = false;
  @Input() showMrStatus = false;

  startMaxDate;
  endMinDate;

  @Output() onLeadFilterUpdate = new EventEmitter<object>();
  @Output() leadFilterData$ = new EventEmitter<object>();

  filterOptions = {
    mrStatus: null,
    partner$: null,
    gender$: null,
    city$: null,
    state$:  null,
    name: null,
    zipCode$: null,
    show: 'all-time',
    startDate: null,
    endDate: null,
  };

  mrStatusSelectedAll = false;
  partnerSelectedAll = false;
  genderSelectedAll = false;
  citySelectedAll = false;
  stateSelectedAll = false;
  zipCodeSelectedAll = false;

  leadFilter = LeadFilter;
  leadFilterCopy = { ...this.leadFilter }; 

  readonly mrStatus: { value: string; label: string }[] = [
    { value: 'Lead Open', label: 'Lead Open' },
    { value: 'Lead Contacted', label: 'Lead Contacted' },
    { value: 'Lead Signed Up', label: 'Lead Signed Up' },
    { value: 'Lead Closed by STOP', label: 'Lead Closed by STOP' },
    { value: 'Lead Enrolled', label: 'Lead Enrolled' }
  ];

  @ViewChild("mrStatusFilter") mrStatusFilter: MatSelect;
  @ViewChild("partnerFilter") partnerFilter: MatSelect;
  @ViewChild("genderFilter") genderFilter: MatSelect;
  @ViewChild("cityFilter") cityFilter: MatSelect;
  @ViewChild("stateFilter") stateFilter: MatSelect;
  @ViewChild("zipCodeFilter") zipCodeFilter: MatSelect;
  
  constructor(
    private partnerService: PartnerService,
    private leadService: LeadService
  ) { }

  ngOnInit(): void {
    this.broadcastUpdate();
    this.loadFilterOptions();
  }

  loadFilterOptions() {
    this.filterOptions.mrStatus = this.mrStatus;

    this.filterOptions.partner$ = this.partnerService.getPartners().pipe(
      map((partners) => {
        return partners.map((val) => {
          return {
            value: val._id,
            label: val.name,
          };
        });
      })
    );

    this.filterOptions.city$ = this.leadService.getCities().pipe(
      map((cities) => {
        return cities.map((item) => {
          const itemToUpper = item?.toUpperCase();
          return {
            value: itemToUpper,
            label: itemToUpper,
          };
        });
      })
    );    

    this.filterOptions.state$ = this.leadService.getStates().pipe(
      map((states) => {
        return states.map(item => {
          const itemToUpper = item?.toUpperCase();
          return {
            value: itemToUpper,
            label: itemToUpper,
          };
        });
      })
    );

    this.filterOptions.zipCode$ = this.leadService.getZipCodes().pipe(
      map((zipCodes) => {
        return zipCodes.map(item => {
          const itemToUpper = item?.toUpperCase();
          return {
            value: itemToUpper,
            label: itemToUpper,
          };
        });
      })
    );

    this.filterOptions.gender$ = this.leadService.getGender().pipe(
      map((genders) => {
        return genders.map(item => {
          const itemToUpper = item?.toUpperCase();
          return {
            value: itemToUpper,
            label: itemToUpper,
          };
        });
      })
    );

  }

  clearFilter() {
    for (let filterOption in this.leadFilter) {
      if (this.leadFilter.hasOwnProperty(filterOption)) {
        this.leadFilter[filterOption] = "";
      }
    }
    this.broadcastUpdate();
  }

  broadcastUpdate() {
    this.leadFilterCopy = { ...this.leadFilter };
    Object.keys(this.leadFilterCopy).forEach((item) => {
      if (
        Array.isArray(this.leadFilterCopy[item]) &&
        this.leadFilterCopy[item].includes("0")
      ) {
        this.untoggleAllSelection(item.toString());
        this.leadFilterCopy[item] = "noData";
      }
    });

    this.leadFilterData$.emit(this.leadFilterCopy);
    this.onLeadFilterUpdate.emit(this.leadFilterCopy);
  }

  untoggleAllSelection(item) {
    switch (item) {
      case "mrStatus":
        this.mrStatusSelectedAll = false;
        this.mrStatusFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "partner":
        this.partnerSelectedAll = false;
        this.partnerFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
      case "gender":
        this.genderSelectedAll = false;
        this.genderFilter.options.forEach((item: MatOption) => {
          if (item.value !== "0") return item.deselect();
          return item.select();
        });
        break;
        case "city":
          this.citySelectedAll = false;
          this.cityFilter.options.forEach((item: MatOption) => {
            if (item.value !== "0") return item.deselect();
            return item.select();
          });
          break;
          case "state":
            this.stateSelectedAll = false;
            this.stateFilter.options.forEach((item: MatOption) => {
              if (item.value !== "0") return item.deselect();
              return item.select();
            });
            break;
          case "zipCode":
            this.zipCodeSelectedAll = false;
            this.zipCodeFilter.options.forEach((item: MatOption) => {
              if (item.value !== "0") return item.deselect();
              return item.select();
            });
            break;
        default:
          break;
    }
  }

  toggleAllSelection(item) {
    switch (item) {
      case "mrStatus":
        this.mrStatusSelectedAll = !this.mrStatusSelectedAll;

        if (this.mrStatusSelectedAll) {
          this.mrStatusFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.mrStatusFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "partner":
        this.partnerSelectedAll = !this.partnerSelectedAll;

        if (this.partnerSelectedAll) {
          this.partnerFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.partnerFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
      case "gender":
        this.genderSelectedAll = !this.genderSelectedAll;

        if (this.genderSelectedAll) {
          this.genderFilter.options.forEach((item: MatOption) => {
            return item.select();
          });
        } else {
          this.genderFilter.options.forEach((item: MatOption) => {
            return item.deselect();
          });
        }
        break;
        case "city":
          this.citySelectedAll = !this.citySelectedAll;
  
          if (this.citySelectedAll) {
            this.cityFilter.options.forEach((item: MatOption) => {
              return item.select();
            });
          } else {
            this.cityFilter.options.forEach((item: MatOption) => {
              return item.deselect();
            });
          }
          break;
          case "state":
            this.stateSelectedAll = !this.stateSelectedAll;
    
            if (this.stateSelectedAll) {
              this.stateFilter.options.forEach((item: MatOption) => {
                return item.select();
              });
            } else {
              this.stateFilter.options.forEach((item: MatOption) => {
                return item.deselect();
              });
            }
            break;
            case "zipCode":
              this.zipCodeSelectedAll = !this.zipCodeSelectedAll;
      
              if (this.zipCodeSelectedAll) {
                this.zipCodeFilter.options.forEach((item: MatOption) => {
                  return item.select();
                });
              } else {
                this.zipCodeFilter.options.forEach((item: MatOption) => {
                  return item.deselect();
                });
              }
              break;
      default:
        break;
    }
  }

  onDateInputChange(whichDate: 'startDate' | 'endDate', momentDate: moment.Moment) {
    if (whichDate === 'startDate') {
      momentDate = momentDate.startOf('day');
      this.leadFilter[whichDate] = momentDate.format();
      this.endMinDate = momentDate.toDate();
    } else if (whichDate === 'endDate') {
      momentDate = momentDate.endOf('day');
      this.leadFilter[whichDate] = momentDate.format();
      this.startMaxDate = momentDate.toDate();
    } else {
      this.leadFilter.startDate = null
      this.leadFilter.endDate = null
    }
  }

  onSelectShowFilter() {
    if (this.filterOptions.show === 'custom-range') {
      const startDate = moment().startOf('day');
      const endDate = moment().endOf('day');

      this.endMinDate = startDate.format();
      this.startMaxDate = endDate.format();

      this.leadFilter['startDate'] = startDate.format();
      this.leadFilter['endDate'] = endDate.format();

      this.filterOptions['startDate'] = startDate.format();
      this.filterOptions['endDate'] = endDate.format();
    } else {
      this.filterOptions.startDate = null
      this.filterOptions.endDate = null
      this.leadFilter['startDate'] = null;
      this.leadFilter['endDate'] = null;
    }

    this.broadcastUpdate();
  }
}
