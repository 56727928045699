
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { ApiService } from './api.service';

import { of } from 'rxjs';

@Injectable()
export class MindRightService {

  onboardingScript = '';

  constructor(
    private api: ApiService
  ) { }

  getOnboardingScript() {
    if (this.onboardingScript) {
      return of(this.onboardingScript);
    }

    return this.api.get('/settings/onboarding-script').pipe(
      map(res => {
        this.onboardingScript = res ? res['value'] : '';
        return this.onboardingScript;
      }));
  }

  saveOnboardingScript(text: string) {
    this.onboardingScript = text;
    return this.api.put('/settings/onboarding-script', { value: text });
  }

  getTags() {
    return this.api.get('/tags');
  }

  createTag(tag) {
    return this.api.post('/tags', tag);
  }

  updateTagName(tagId, newName) {
    return this.api.patch('/tags/' + tagId, {
      newName
    });
  }

  deleteTag(tagId) {
    return this.api.delete('/tags/' + tagId);
  }

  getScheduledMessages() {
    return this.api.get('/app/scheduled-messages');
  }

  createScheduledMessage(schedule) {
    return this.api.post('/app/scheduled-messages', schedule);
  }

  editScheduledMessage(scheduleId, payload) {
    return this.api.put('/app/scheduled-messages/' + scheduleId, payload);
  }

  deleteScheduledMessage(scheduleId) {
    return this.api.delete('/app/scheduled-messages/' + scheduleId);
  }

  getLeadScheduledMessages() {
    return this.api.get('/app/lead-scheduled-messages');
  }

  createLeadScheduledMessage(leadSchedule) {
    return this.api.post('/app/lead-scheduled-messages', leadSchedule);
  }

  editLeadScheduledMessage(leadScheduleId, payload) {
    return this.api.put('/app/lead-scheduled-messages/' + leadScheduleId, payload);
  }

  deleteLeadScheduledMessage(leadScheduleId) {
    return this.api.delete('/app/lead-scheduled-messages/' + leadScheduleId);
  }

  getIntercomCredentials() {
    return this.api
      .get("/app/get-intercom-credentials")
      .pipe(map((res: any) => res));
  }
}
